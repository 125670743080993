import { LoadingIndicator } from '@taycor/ui-library';
import React, { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';

const Router: FC = () => {
    return (
        <Suspense fallback={<LoadingIndicator></LoadingIndicator>}>
            <Routes>
                <Route path="/" element={<Landing></Landing>}></Route>
                {/* <Navigate to={"/"} /> */}
            </Routes>
        </Suspense>
    );
};

export default Router;
