import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Typography } from '@material-ui/core';
import { LoadingIndicator } from '@taycor/ui-library';
import React, { FC, useState, useEffect, useCallback } from 'react';
import authConfig from '../../authConfig';
import Layout from '../../components/Layout/Layout';
import LeadPanel from '../../components/LeadPanel/LeadPanel';
import SweeperCard from '../../components/SweeperCard/SweeperCard';
import config from '../../config';
import { API_ROUTE_RULES } from '../../lib/defs';
import { useCurrentLead } from '../../lib/hooks/useCurrentLead';
import './styles.css';

const Landing: FC = () => {
    const [rules, setRules] = useState<Array<Rule>>([]);
    const [lead, setLead] = useState<Lead | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>('');
    const [idToken, setIdToken] = useState<string>('');

    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();
    const showLoadingIndicator = inProgress !== 'none';

    if (!showLoadingIndicator && !isAuthenticated) {
        instance.loginRedirect(authConfig.msal.loginRequest);
    }

    const { getCurrentLead, setCurrentLead } = useCurrentLead();
    const onContactLead = () => {
        setCurrentLead(null);
    };

    useEffect(() => {
        const accessTokenRequest = {
            scopes: ['user.read'],
            account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((tokenResponse: any) => {
                    // Acquire token silent success
                    setAccessToken(accessToken);
                    setIdToken(tokenResponse.idToken);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    }, [instance, accounts, inProgress, accessToken]);

    const renderContent = () => {
        return (
            <>
                <AuthenticatedTemplate>
                    <div className="leftPanel">
                        <Typography className="panelTitle" variant="h3">
                            Lead sweeper
                        </Typography>
                        {rules.length > 0 &&
                            rules.map((rule) => (
                                <SweeperCard
                                    key={rule.id}
                                    rule={rule}
                                    disabled={isLoading}
                                    onRun={() => {
                                        getLead(rule.id);
                                    }}
                                    onExport={
                                        config.showExportLeads.includes(rule.id)
                                            ? () => {
                                                  exportLeads(rule.id);
                                              }
                                            : null
                                    }
                                ></SweeperCard>
                            ))}
                    </div>
                    <div className="rightPanel">
                        {!isLoading && lead && <LeadPanel lead={lead} onContactLead={onContactLead}></LeadPanel>}
                        {isLoading && <LoadingIndicator></LoadingIndicator>}
                    </div>
                </AuthenticatedTemplate>
            </>
        );
    };

    useEffect(() => {
        if (idToken) {
            setIsLoading(true);

            let rulesUrl = API_ROUTE_RULES;

            const url = `${config.apiUrl}${rulesUrl}`;
            const headers = new Headers();
            const bearer = `Bearer ${idToken}`;

            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            };

            fetch(url, options)
                .then((response) => response.json())
                .then((data) => {
                    setRules(data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [idToken]);

    const getLead = useCallback(
        (ruleId: string) => {
            setIsLoading(true);

            const existingLead = getCurrentLead();
            if (existingLead) {
                setLead(existingLead);
                setIsLoading(false);
                return;
            }

            const url = `${config.apiUrl}${API_ROUTE_RULES}/${ruleId}`;
            const headers = new Headers();
            const bearer = `Bearer ${idToken}`;

            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');
            fetch(url, {
                method: 'GET',
                headers: headers,
            })
                .then((response) => response.json())
                .then((data) => {
                    setLead(data);
                    setCurrentLead(data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [idToken, getCurrentLead, setCurrentLead],
    );

    const exportLeads = useCallback(
        (ruleId: string) => {
            setIsLoading(true);

            const url = `${config.apiUrl}${API_ROUTE_RULES}/${ruleId}/export`;
            const headers = new Headers();
            const bearer = `Bearer ${idToken}`;

            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');
            fetch(url, {
                method: 'GET',
                headers: headers,
            })
                .then((response) => response.blob())
                .then((blob) => URL.createObjectURL(blob))
                .then((url) => {
                    setIsLoading(false);
                    window.open(url, '_blank');
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [idToken],
    );

    return (
        <Layout>
            <>
                {showLoadingIndicator && (
                    <div className="rightPanel">
                        {' '}
                        <LoadingIndicator />
                    </div>
                )}
                {!showLoadingIndicator && renderContent()}
            </>
        </Layout>
    );
};

export default Landing;
