// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leadRow {
  display: flex;
  width: calc(100% - 24px);
  padding-right: 24px;
}

.leadRowLabel {
  color: #707172;
  padding: 0px 12px 0px 0px;
  box-sizing: border-box;
  width: 144px;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dadee7;
}

.leadRowValue {
  color: #383939;
  padding: 0px 12px 0px 0px;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dadee7;
}

.leadRow.last .leadRowLabel,
.leadRow.last .leadRowValue {
  border-bottom: 1px solid #707172;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeadRow/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gCAAgC;AAClC;;AAEA;;EAEE,gCAAgC;AAClC","sourcesContent":[".leadRow {\n  display: flex;\n  width: calc(100% - 24px);\n  padding-right: 24px;\n}\n\n.leadRowLabel {\n  color: #707172;\n  padding: 0px 12px 0px 0px;\n  box-sizing: border-box;\n  width: 144px;\n  height: 44px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #dadee7;\n}\n\n.leadRowValue {\n  color: #383939;\n  padding: 0px 12px 0px 0px;\n  box-sizing: border-box;\n  height: 44px;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  border-bottom: 1px solid #dadee7;\n}\n\n.leadRow.last .leadRowLabel,\n.leadRow.last .leadRowValue {\n  border-bottom: 1px solid #707172;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
