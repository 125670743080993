import "./styles.css";
import React, { FC } from "react";
import { Typography } from "@material-ui/core";

interface LeadRowProps {
  label: string;
  value?: string | number | null;
  isLast?: boolean;
}

const LeadRow: FC<LeadRowProps> = ({ label, value, isLast }) => {
  return (
    <div className={(isLast ? "last " : "") + "leadRow"}>
      <Typography variant="body2" className="leadRowLabel">
        {label}
      </Typography>
      <Typography variant="body2" className="leadRowValue">
        {value}
      </Typography>
    </div>
  );
};

export default LeadRow;
