import React, { FC } from "react";
import "./styles.css";
import SweeperHeader from "../SweeperHeader";

const Layout: FC = ({ children }) => {
  return (
    <>
      <SweeperHeader />
      <div className="contentArea">{children}</div>
    </>
  );
};

export default Layout;
