// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftPanel {
    flex: 0 1 500px;
    padding: 48px;
    background-color: #f5f5f5;
    height: auto;
    position: absolute;
}

.rightPanel {
    height: 100%;
    flex: 1 1 auto;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    right: calc(50% - 250px);

}

.MuiTypography-root.panelTitle {
    margin-bottom: 24px;
    color: #383939;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,wBAAwB;;AAE5B;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".leftPanel {\n    flex: 0 1 500px;\n    padding: 48px;\n    background-color: #f5f5f5;\n    height: auto;\n    position: absolute;\n}\n\n.rightPanel {\n    height: 100%;\n    flex: 1 1 auto;\n    background-color: #ffffff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    position: fixed;\n    right: calc(50% - 250px);\n\n}\n\n.MuiTypography-root.panelTitle {\n    margin-bottom: 24px;\n    color: #383939;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
