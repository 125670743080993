import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@taycor/ui-library";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Router from "./Router";
import authConfig from "./authConfig";
const pcaInstance = new PublicClientApplication(authConfig.msal.config);

function App() {
  return (
    <MsalProvider instance={pcaInstance}>
      <ThemeProvider name="default">
        <BrowserRouter>
          <Router></Router>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
