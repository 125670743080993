import React, { FC } from "react";
import "./styles.css";

const SweeperHeader: FC = () => {
  return (
    <div className="sweeperHeader">
      <img src="tflogo.png" className="tflogo" alt="tfg" />
    </div>
  );
};

export default SweeperHeader;
