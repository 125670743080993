import "./styles.css";
import React, { FC } from "react";
import LeadRow from "../LeadRow/LeadRow";
import { Typography } from "@material-ui/core";

interface LeadDetailsProps {
  lead?: Lead | null;
}

const LeadDetails: FC<LeadDetailsProps> = ({ lead }) => {
  return (
    (!!lead && (
      <>
        <Typography variant="overline" className="leadDetailHeading">
          Company Details
        </Typography>
        <LeadRow label="Name" value={lead.comp_name}></LeadRow>
        <LeadRow label="ID" value={lead.companyid}></LeadRow>
        <LeadRow
          label="Address"
          value={`${lead.comp_address + ", " || ""}${
            lead.comp_city + ", " || ""
          }${lead.comp_state + ", " || ""}${lead.comp_zip}`}
        ></LeadRow>
        <LeadRow label="Email" value={lead.pers_emailaddress}></LeadRow>
        <LeadRow label="Phone" value={lead.pers_phone} isLast></LeadRow>

        <Typography variant="overline" className="leadDetailHeading">
          Sales
        </Typography>
        <LeadRow
          label="Last touch"
          value={
            lead.last_touch ? new Date(lead.last_touch).toLocaleString() : ""
          }
        ></LeadRow>
        <LeadRow
          label="Next touch"
          value={
            lead.next_touch ? new Date(lead.next_touch).toLocaleString() : ""
          }
        ></LeadRow>
        <LeadRow
          label="Lead Date"
          value={new Date(lead.lead_date).toLocaleString()}
        ></LeadRow>
        <LeadRow label="Vendor name" value={lead.vendor_name}></LeadRow>
        <LeadRow label="Status" value={lead.lead_status}></LeadRow>
        <LeadRow label="Type" value={lead.lead_type} isLast></LeadRow>

        <Typography variant="overline" className="leadDetailHeading">
          Owner Information
        </Typography>
        <LeadRow
          isLast
          label="Manager"
          value={lead.pers_firstname + " " + lead.pers_lastname}
        ></LeadRow>
      </>
    )) || <></>
  );
};

export default LeadDetails;
