import "./styles.css";
import React, { FC } from "react";
import { Button } from "@taycor/ui-library";
import { Typography } from "@material-ui/core";

interface SweeperCardProps {
  rule: Rule;
  disabled?: boolean;
  onRun: () => void;
  onExport?: () => void;
}

const SweeperCard: FC<SweeperCardProps> = ({
  rule,
  disabled,
  onRun,
  onExport,
}) => {
  return (
    <div className="sweeperCard">
      <Typography variant="subtitle1">{rule.friendly_name}</Typography>
      <Typography variant="body1">{rule.description}</Typography>
      <Button
        variant="contained"
        label="Run"
        color="secondary"
        action={onRun}
        disabled={disabled}
      ></Button>
      {onExport && (
        <Button
          variant="outlined"
          label="Export Leads"
          color="secondary"
          action={onExport}
          disabled={disabled}
        ></Button>
      )}
    </div>
  );
};

export default SweeperCard;
