import { useCallback } from 'react';
import config from '../../config';
import { getCookie, setCookie } from '../cookies';

export const useCurrentLead = () => {
    const setCurrentLead = useCallback((lead: Lead | null) => {
        if (!config.contactNoContactEnabled) {
            return;
        }

        setCookie(
            document,
            config.sweeperLeadCookieName,
            JSON.stringify(lead),
            lead ? config.sweeperLeadCookieMaxAge : 0,
        );
    }, []);

    const getCurrentLead = useCallback((): Lead | null => {
        if (!config.contactNoContactEnabled) {
            return null;
        }

        const existingLeadCookie = getCookie(document, config.sweeperLeadCookieName);
        return existingLeadCookie ? JSON.parse(existingLeadCookie) : null;
    }, []);

    return { setCurrentLead, getCurrentLead };
};
