// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noteBox {
  margin-top: 24px;
}

.noteContent {
  background: #f2f4f7;
  border-radius: 2px 2px 2px 8px;
  padding: 16px;
  width: 91%;
  color: #252626;
}

.noteInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.noteInfo .MuiTypography-body2 {
  color: #252626;
  font-weight: bold;
}
.noteInfo .MuiTypography-caption {
  color: #707172;
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeadNotes/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".noteBox {\n  margin-top: 24px;\n}\n\n.noteContent {\n  background: #f2f4f7;\n  border-radius: 2px 2px 2px 8px;\n  padding: 16px;\n  width: 91%;\n  color: #252626;\n}\n\n.noteInfo {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.noteInfo .MuiTypography-body2 {\n  color: #252626;\n  font-weight: bold;\n}\n.noteInfo .MuiTypography-caption {\n  color: #707172;\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
