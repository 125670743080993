import { Link, Typography } from '@material-ui/core';
import { Button, Shown } from '@taycor/ui-library';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import config from '../../config';
import { API_ROUTE_LEAD_NOTES } from '../../lib/defs';
import LeadDetails from '../LeadDetails/LeadDetails';
import LeadNotes from '../LeadNotes/LeadNotes';
import './styles.css';

interface LeadPanelProps {
    lead?: Lead | null;
    onContactLead: () => void;
}

const LeadPanel: FC<LeadPanelProps> = ({ lead, onContactLead }) => {
    const hasLead = !!lead;
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const cid = lead.companyid,
            crmsid = searchParams.get('crm_sid'),
            crmurl = searchParams.get('crm_url');

        if (crmsid && crmurl)
            setCrmData({
                sid: crmsid,
                url: crmurl,
                curl: `${crmurl}/crm/eware.dll/Do?SID=${crmsid}&Act=200&Mode=1&CLk=T&Key0=1&Key1=${cid}&Comp_CompanyId=${cid}`,
            });
    }, [searchParams, lead.companyid]);

    const [tab, setTab] = useState<'details' | 'notes'>('details');
    const [notes, setNotes] = useState<Note[]>([]);
    const [crmData, setCrmData] = useState<{
        sid: string;
        url: string;
        curl: string;
    }>({ sid: '', url: '', curl: '' });

    useEffect(() => {
        if (!hasLead || !lead.companyid) return;

        const url = `${config.apiUrl}${API_ROUTE_LEAD_NOTES.replace(':id', lead.companyid.toString())}`;
        fetch(url, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                setNotes(data);
            })
            .catch(() => {});
    }, [lead, hasLead]);

    const openCRMLink = () => {
        if (!crmData.sid || !crmData.curl) return;
        if (!window || !window.parent || !window.parent.postMessage) return;
        window.parent.postMessage(crmData.curl, crmData.url);
    };

    const contactClick = () => {
        onContactLead();
    };
    const noContactClick = () => {
        onContactLead();
    };

    return (
        (hasLead && (
            <div className="leadPanelPanel">
                <div className="leadPanelTop">
                    {config.contactNoContactEnabled && (
                        <div className="leadContactNoContact">
                            <Button
                                variant="contained"
                                label="Contact"
                                color="secondary"
                                action={contactClick}
                            ></Button>
                            <Button
                                variant="outlined"
                                label="No Contact"
                                color="secondary"
                                action={noContactClick}
                            ></Button>
                        </div>
                    )}
                    <Typography variant="overline" className="leadPanelId">
                        {crmData.sid ? (
                            <Link
                                onClick={() => {
                                    openCRMLink();
                                }}
                            >
                                {lead.companyid}
                            </Link>
                        ) : (
                            lead.companyid
                        )}
                    </Typography>
                    <div className="leadPanelHeader">
                        <Typography variant="h4" className="leadPanelTitle">
                            {lead.comp_name}
                        </Typography>
                    </div>
                    <div className="leadPanelTabs">
                        <Link
                            onClick={() => {
                                setTab('details');
                            }}
                        >
                            <Typography
                                variant="body1"
                                className={'leadPanelTab' + (tab === 'details' ? ' active' : '')}
                            >
                                Details
                            </Typography>
                        </Link>
                        <Link
                            onClick={() => {
                                setTab('notes');
                            }}
                        >
                            <Typography variant="body1" className={'leadPanelTab' + (tab === 'notes' ? ' active' : '')}>
                                Notes
                            </Typography>
                        </Link>
                    </div>
                </div>
                <div className="leadPanelBottom">
                    {tab === 'details' && <LeadDetails lead={lead}></LeadDetails>}
                    {tab === 'notes' && <LeadNotes notes={notes}></LeadNotes>}
                </div>
            </div>
        )) || (
            <>
                <Shown />
                <Typography variant="body1" className="noLeadMessage">
                    Select a lead and the information will be shown here
                </Typography>
            </>
        )
    );
};

export default LeadPanel;
