// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sweeperCard {
  width: 400px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin-bottom: 16px;
}

.sweeperCard .MuiTypography-subtitle1 {
  font-weight: 600;
  margin-bottom: 8px;
}

.sweeperCard .MuiTypography-body1 {
  color: #707172;
}

.sweeperCard .MuiButtonBase-root {
  width: 100%;
  margin-top: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SweeperCard/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iDAAiD;EACjD,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".sweeperCard {\n  width: 400px;\n  padding: 24px;\n  background: #ffffff;\n  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.16);\n  border-radius: 4px;\n  margin-bottom: 16px;\n}\n\n.sweeperCard .MuiTypography-subtitle1 {\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n\n.sweeperCard .MuiTypography-body1 {\n  color: #707172;\n}\n\n.sweeperCard .MuiButtonBase-root {\n  width: 100%;\n  margin-top: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
