import "./styles.css";
import React, { FC } from "react";
import { Typography } from "@material-ui/core";

interface LeadNotesProps {
  notes?: Note[] | null;
}

const LeadNotes: FC<LeadNotesProps> = ({ notes }) => {
  const hasNotes = notes !== undefined && Array.isArray(notes);

  return (
    (hasNotes && (
      <>
        {notes.map((n) => (
          <div className="noteBox">
            <div className="noteContent">
              {(n.comm_note || "").split("\n").map((line) => (
                <Typography>{line}</Typography>
              ))}
            </div>
            <div className="noteInfo">
              <Typography variant="body2">
                {n.user_firstname + " " + n.user_lastname}
              </Typography>
              <Typography variant="caption">
                {new Date(n.comm_createddate).toLocaleDateString("en-US")}
              </Typography>
            </div>
          </div>
        ))}
      </>
    )) || <></>
  );
};

export default LeadNotes;
