// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sweeperHeader {
  background-color: #14679f;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tflogo {
  height: 25px;
  width: 25px;
  margin: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SweeperHeader/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;AAChB","sourcesContent":[".sweeperHeader {\n  background-color: #14679f;\n  height: 48px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.tflogo {\n  height: 25px;\n  width: 25px;\n  margin: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
